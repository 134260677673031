import { Formik, FormikContextType } from 'formik'
import { Col, Form, Modal, Row, Stack } from 'react-bootstrap'
import * as yup from 'yup'
import Button from '../../Components/Button'
import api from '../../services/api'
import { Switch } from '@mui/material'

const styles = {
  modal: {
    display: 'inline-block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}

interface User {
  id: number
  nome: string
  sobrenome: string
  CPF: string
  unidade_id: number
  dataNasc: string
  telefone: string
  usuario_img: string
  email: string
  blocked_in_unit: number
  login_blocked: number
  nature_identification: string
  block_and_lot: string
  observations: string
}

interface IUserModal {
  userData: User
  show: boolean
  onHide: () => void
  parentCallback: () => Promise<void>
  edit?: boolean
}

export default function UserModal({
  userData,
  onHide,
  parentCallback,
  show,
}: IUserModal) {
  const schema = yup.object({
    nome: yup.string().min(2).required('Digite um nome para o usuário.'),
    sobrenome: yup
      .string()
      .min(2)
      .required('Digite um sobrenome para o usuário.'),
    email: yup
      .string()
      .email('E-mail inválido')
      .required('Digite o email do usuário.'),
    CPF: yup.string().length(11).required('Digite o CPF do usuário.'),
    unidade_id: yup.number().required('Escolha a unidade do usuário.'),
    dataNasc: yup.date(),
    telefone: yup.string().min(10).max(11),
    observations: yup.string().notRequired(),
  })

  const handleSubmit = (event) => {
    try {
      const changedData = {
        nome: event.nome.replace(/^\s+|\s+$/g, ''),
        sobrenome: event.sobrenome.replace(/^\s+|\s+$/g, ''),
        email: event.email,
        cpf: event.CPF,
        unidade_id: event.unidade_id,
        data_nascimento: event.dataNasc,
        telefone: event.telefone,
        blocked_in_unit: event.blocked_in_unit,
        login_blocked: event.login_blocked,
        observations: event.observations,
      }

      api.put(`/users/admin-update/${event.id}`, changedData).then(() => {
        parentCallback()
        onHide()
      })
    } catch (err) {
      alert(err)
      console.log(err)
    }
  }

  return (
    <Modal show={show} aria-labelledby="contained-modal-title-vcenter" centered>
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Editar dados do Usuário
        </Modal.Title>
      </Modal.Header>
      <div style={styles.modal}>
        <Modal.Body>
          <Formik
            enableReinitialize
            validationSchema={schema}
            onSubmit={handleSubmit}
            initialValues={userData}
          >
            {({
              handleSubmit,
              handleChange,
              values,
              touched,
              errors,
              setFieldValue,
            }: FormikContextType<User>) => (
              <Form noValidate id="productForm" onSubmit={handleSubmit}>
                <Row>
                  <img
                    src={userData?.usuario_img}
                    style={{
                      maxWidth: '120px',
                      maxHeight: '120px',
                    }}
                  />
                  <Row style={{ marginTop: '12px' }}>
                    <Form.Group as={Col} md="6" controlId="validacaoNome">
                      <Form.Label>Nome</Form.Label>
                      <Form.Control
                        placeholder="Nome"
                        type="text"
                        name="nome"
                        value={values.nome}
                        onChange={handleChange}
                        isInvalid={!!errors.nome}
                        isValid={touched.nome && !errors.nome}
                      />
                      <Form.Control.Feedback type="invalid">
                        Nome é um campo obrigatório!
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group as={Col} md="6" controlId="validacaoNome">
                      <Form.Label>Sobrenome</Form.Label>
                      <Form.Control
                        placeholder="Digite o sobrenome"
                        type="text"
                        name="sobrenome"
                        value={values.sobrenome}
                        onChange={handleChange}
                        isInvalid={!!errors.sobrenome}
                        isValid={touched.sobrenome && !errors.sobrenome}
                      />
                      <Form.Control.Feedback type="invalid">
                        Sobrenome é um campo obrigatório!
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                  <Row style={{ marginTop: '12px' }}>
                    <Form.Group as={Col} md="6" controlId="validacaoEmail">
                      <Form.Label>E-mail</Form.Label>
                      <Form.Control
                        placeholder="E-mail"
                        type="text"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        isInvalid={!!errors.email}
                        isValid={touched.email && !errors.email}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validacaoCPF">
                      <Form.Label>CPF</Form.Label>
                      <Form.Control
                        placeholder="CPF"
                        type="text"
                        name="CPF"
                        value={values.CPF}
                        onChange={handleChange}
                        isInvalid={!!errors.CPF}
                        isValid={touched.CPF && !errors.CPF}
                      />
                    </Form.Group>
                  </Row>

                  <Row style={{ marginTop: '12px' }}>
                    <Form.Group as={Col} md="6" controlId="validacaoDataNasc">
                      <Form.Label>Data de Nascimento</Form.Label>
                      <Form.Control
                        placeholder="dataNasc"
                        type="date"
                        name="dataNasc"
                        value={values.dataNasc}
                        onChange={handleChange}
                        isInvalid={!!errors.dataNasc}
                        isValid={touched.dataNasc && !errors.dataNasc}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="6" controlId="validacaoTelefone">
                      <Form.Label>Telefone</Form.Label>
                      <Form.Control
                        placeholder="telefone"
                        type="text"
                        name="telefone"
                        value={values.telefone}
                        onChange={handleChange}
                        isInvalid={!!errors.telefone}
                        isValid={touched.telefone && !errors.telefone}
                      />
                    </Form.Group>
                  </Row>

                  <Row style={{ marginTop: '12px' }}>
                    <Form.Group controlId="validacaoObservacoes">
                      <Form.Label>Observações</Form.Label>
                      <Form.Control
                        placeholder="Observações"
                        as="textarea"
                        type="text"
                        name="observations"
                        value={values.observations}
                        onChange={handleChange}
                        isInvalid={!!errors.observations}
                        isValid={touched.observations && !errors.observations}
                      />
                    </Form.Group>
                  </Row>

                  <Row style={{ marginTop: '12px' }}>
                    <Col md="6">
                      <p style={{ marginBottom: 0 }}>
                        Tipo de cadastro:{' '}
                        <p style={{ textTransform: 'capitalize' }}>
                          {values.nature_identification}
                        </p>
                      </p>
                    </Col>

                    <Col md="6">
                      <p style={{ marginBottom: 0 }}>
                        Quadra e Lote:{' '}
                        <p style={{ textTransform: 'capitalize' }}>
                          {values.block_and_lot}
                        </p>
                      </p>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="validacaoBlock">
                        <Form.Label>Acesso Bloqueado</Form.Label>
                        <Switch
                          name="blocked_in_unit"
                          defaultChecked={Boolean(values.blocked_in_unit)}
                          onChange={(event) =>
                            setFieldValue(
                              'blocked_in_unit',
                              event.target.checked,
                            )
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="validacaoBlock">
                        <Form.Label>Login Bloqueado</Form.Label>
                        <Switch
                          name="login_blocked"
                          defaultChecked={Boolean(values.login_blocked)}
                          onChange={(event) =>
                            setFieldValue('login_blocked', event.target.checked)
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Row>
              </Form>
            )}
          </Formik>
        </Modal.Body>
      </div>
      <Modal.Footer>
        <Button onClick={onHide}>Cancelar</Button>
        <Button form="productForm" type="submit">
          Salvar
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
